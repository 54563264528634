// 练习详情-公用样式
.outer_container {
  text-align: left;
  position: relative;
  .bg_white {
    background-color: white;
  }
  .align_center {
    display: flex;
    align-items: center;
  }
  .anticon-yyashow {
    &.anticon-bofang,
    &.anticon-zanting {
      display: inline;
    }
    &.anticon-cuowu,
    &.anticon-duihao {
      display: inline-block;
      margin-right: 0;
    }
  }
  .inner_progress_box {
    position: relative;
    flex: 2;
    height: 100%;
    max-width: 500px;
  }
  // 分数拦
  .score_box {
    height: 60px;
    line-height: 60px;
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
  }
  // 评分说明
  .tips_content {
    display: flex;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    color: #999;
    .single_content {
      margin-right: 30px;
    }
  }
  // 问题内容
  .question_content {
    // 单个问题
    .question_item:first-child {
      margin-top: 0;
      border: none;
    }
    .question_item {
      color: #999;
      border-top: solid 4px #f2f2f2;
      position: relative;
      .audio_text {
        padding-left: 30px;
        background: white;
        position: relative;
        .question_number {
          width: 30px;
          height: 30px;
          color: white;
          position: absolute;
          top: 0;
          left: 0;
          text-align: left;
          border-bottom-right-radius: 40px;
          padding-left: 10px;
          box-sizing: border-box;
        }
        .audio_box {
          display: flex;
          height: 50px;
          flex: 2;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .anticon-bofang,
          .anticon-zanting {
            font-size: 30px;
            line-height: 30px;
          }
          .text_status {
            padding-right: 30px;
          }
        }
      }
      // 音频原文
      .audio_content {
        overflow: hidden;
        .inner_text {
          padding: 10px 20px;
        }
      }
      // 听力填空中的图片
      .img_box {
        position: relative;
        text-align: center;
        background: white;
        padding: 10px 0;
        .symbol_img {
          width: 300px;
        }
        .file_url {
          width: 500px;
        }
        .right_btn {
          position: absolute;
          bottom: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          color: white;
          padding: 0px 20px;
        }
      }
      .question_title {
        background-color: white;
        padding: 10px 20px;
        &.small {
          // font-size: 30rpx;
        }
        &.text_center {
          text-align: center;
        }
      }
      .question_item_content {
        font-size: 1rem;
      }
      //  情景回答的情景提示
      .scene_content {
        // font-size: 34rpx;
        padding: 10px 20px;
        background-color: white;
      }
      // 选择题
      .radio_group {
        display: flex;
        flex-wrap: wrap;
        padding: 0 20px;
        padding-bottom: 0;
        background-color: white;
        .label_item {
          font-size: 16px;
          line-height: 40px;
          width: 50%;
          display: flex;
          align-items: baseline;
          &.full_width {
            width: 100%;
          }
          .index_margin {
            margin: 0 10px;
          }
          .index_add {
            margin: 0 10px;
          }
          .radio_img {
            width: 200px;
            margin-left: 20px;
          }
        }
      }
      .choose_text {
        padding: 10px 20px;
        background-color: white;
        &.flex_between {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        // 录音回答中图标居中
        .anticon-yyashow {
          vertical-align: middle;
          font-size: 30px;
          line-height: 30px;
        }
        .noUsed {
          color: red;
        }
      }
      // 得分流利度等
      .other_text {
        display: flex;
        padding: 20px 0;
        // font-size: 30rpx;
        .box {
          width: 300px;
          text-align: center;
          display: flex;
          flex-direction: column;
        }
      }
      // 关键词等
      .other_scene_text {
        background-color: white;
        padding: 10px 20px;
        display: flex;
        margin-top: 1px;
        .answer_key {
          flex: 2;
          .hasBottom {
            border-bottom: solid 1px #ddd;
          }
          .hasBottom:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}
