.outer_container {
  text-align: left;
  position: relative;
}
.outer_container .bg_white {
  background-color: white;
}
.outer_container .align_center {
  display: flex;
  align-items: center;
}
.outer_container .anticon-yyashow.anticon-bofang,
.outer_container .anticon-yyashow.anticon-zanting {
  display: inline;
}
.outer_container .anticon-yyashow.anticon-cuowu,
.outer_container .anticon-yyashow.anticon-duihao {
  display: inline-block;
  margin-right: 0;
}
.outer_container .inner_progress_box {
  position: relative;
  flex: 2;
  height: 100%;
  max-width: 500px;
}
.outer_container .score_box {
  height: 60px;
  line-height: 60px;
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
}
.outer_container .tips_content {
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  color: #999;
}
.outer_container .tips_content .single_content {
  margin-right: 30px;
}
.outer_container .question_content .question_item:first-child {
  margin-top: 0;
  border: none;
}
.outer_container .question_content .question_item {
  color: #999;
  border-top: solid 4px #f2f2f2;
  position: relative;
}
.outer_container .question_content .question_item .audio_text {
  padding-left: 30px;
  background: white;
  position: relative;
}
.outer_container .question_content .question_item .audio_text .question_number {
  width: 30px;
  height: 30px;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  border-bottom-right-radius: 40px;
  padding-left: 10px;
  box-sizing: border-box;
}
.outer_container .question_content .question_item .audio_text .audio_box {
  display: flex;
  height: 50px;
  flex: 2;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.outer_container .question_content .question_item .audio_text .audio_box .anticon-bofang,
.outer_container .question_content .question_item .audio_text .audio_box .anticon-zanting {
  font-size: 30px;
  line-height: 30px;
}
.outer_container .question_content .question_item .audio_text .audio_box .text_status {
  padding-right: 30px;
}
.outer_container .question_content .question_item .audio_content {
  overflow: hidden;
}
.outer_container .question_content .question_item .audio_content .inner_text {
  padding: 10px 20px;
}
.outer_container .question_content .question_item .img_box {
  position: relative;
  text-align: center;
  background: white;
  padding: 10px 0;
}
.outer_container .question_content .question_item .img_box .symbol_img {
  width: 300px;
}
.outer_container .question_content .question_item .img_box .file_url {
  width: 500px;
}
.outer_container .question_content .question_item .img_box .right_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0px 20px;
}
.outer_container .question_content .question_item .question_title {
  background-color: white;
  padding: 10px 20px;
}
.outer_container .question_content .question_item .question_title.text_center {
  text-align: center;
}
.outer_container .question_content .question_item .question_item_content {
  font-size: 1rem;
}
.outer_container .question_content .question_item .scene_content {
  padding: 10px 20px;
  background-color: white;
}
.outer_container .question_content .question_item .radio_group {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  padding-bottom: 0;
  background-color: white;
}
.outer_container .question_content .question_item .radio_group .label_item {
  font-size: 16px;
  line-height: 40px;
  width: 50%;
  display: flex;
  align-items: baseline;
}
.outer_container .question_content .question_item .radio_group .label_item.full_width {
  width: 100%;
}
.outer_container .question_content .question_item .radio_group .label_item .index_margin {
  margin: 0 10px;
}
.outer_container .question_content .question_item .radio_group .label_item .index_add {
  margin: 0 10px;
}
.outer_container .question_content .question_item .radio_group .label_item .radio_img {
  width: 200px;
  margin-left: 20px;
}
.outer_container .question_content .question_item .choose_text {
  padding: 10px 20px;
  background-color: white;
}
.outer_container .question_content .question_item .choose_text.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.outer_container .question_content .question_item .choose_text .anticon-yyashow {
  vertical-align: middle;
  font-size: 30px;
  line-height: 30px;
}
.outer_container .question_content .question_item .choose_text .noUsed {
  color: red;
}
.outer_container .question_content .question_item .other_text {
  display: flex;
  padding: 20px 0;
}
.outer_container .question_content .question_item .other_text .box {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.outer_container .question_content .question_item .other_scene_text {
  background-color: white;
  padding: 10px 20px;
  display: flex;
  margin-top: 1px;
}
.outer_container .question_content .question_item .other_scene_text .answer_key {
  flex: 2;
}
.outer_container .question_content .question_item .other_scene_text .answer_key .hasBottom {
  border-bottom: solid 1px #ddd;
}
.outer_container .question_content .question_item .other_scene_text .answer_key .hasBottom:last-child {
  border-bottom: none;
}
.mask_outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.mask_outer .img_url {
  width: 60%;
}
