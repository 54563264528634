




















































































































































































































@import './index.less';
.mask_outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .img_url {
    width: 60%;
  }
}
